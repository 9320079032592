footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(to right, #6f1b3d, #000b24);
    backdrop-filter: blur(30px);
    color: white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    footer {
        font-size: 12px;
        padding: 5px;
        bottom: 0dvh;
        /*position: relative;*/
        /*bottom: 0;*/
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/*@media only screen and (min-width: 600px) {*/
/*    footer {*/
/*        font-size: 14px;*/
/*        padding: 10px;*/
/*    }*/
/*}*/

/* Medium devices (landscape tablets, 768px and up) */
/*@media only screen and (min-width: 768px) {*/
/*    footer {*/
/*        font-size: 16px;*/
/*        padding: 15px;*/
/*    }*/
/*}*/

/* Large devices (laptops/desktops, 992px and up) */
/*@media only screen and (min-width: 992px) {*/
/*    footer {*/
/*        font-size: 18px;*/
/*        padding: 20px;*/
/*    }*/
/*}*/