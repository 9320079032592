.mainFinder {
    display: flex;
    /*margin-top: 100px;*/
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
    height: 540px;
    background-color: rgba(17, 25, 40, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.mainFinderNav {
    background-color: rgba(17, 25, 40, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.125);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    /*background: green;*/
    width: 250px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 10px;
}

.mainFinderContent {
    /*background: red;*/
    width: 950px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 25px;
    right: 30px;
}

form {
    display: flex;
}

.mainFinderInput {
    background: #1f2a37;
    border-bottom: 1px solid #4b5663;
    border-left: 1px solid #4b5663;
    border-top: 1px solid #4b5663;
    border-right: none;
    width: 150px;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    color: white;
    transition: border-color 0.3s ease;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    /*margin-top: -5px;*/
}

.mainFinderInput:focus,
.mainFinderInput:hover {
    outline: none;
    border-color: #2ea44f;
}

/*input:focus {*/
/*    outline: none;*/
/*}*/

.mainFinderButton {
    color: white;
    background: #1f2a37;
    border-bottom: 1px solid #4b5663;
    border-right: 1px solid #4b5663;
    border-top: 1px solid #4b5663;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 30px;
    /*font-size: 22px;*/
    text-align: center;
    height: 34px;
    /*margin-top: 1px;*/
    cursor: pointer;
    transition: border-color 0.3s ease;
    /*padding-bottom: 12px;*/
    /*padding-top: 10px;*/
}

.mainFinderButtonText {
    font-size: 16px;
}

.mainFinderButton:hover {
    /*border-left: 1px solid #4b5663;*/
    outline: none;
    border-color: #2ea44f;
}

.mainFinderButtonIcon {
    margin-top: 3px;
    /*height: 16px;*/
    /*height: 11px;*/
}

.mainFinderNavSearch {
    display: flex;
}

.noImagesText {
    color: #505050;
    margin-top: 256px;
}

.imagesBox {
    display: flex;
    flex-wrap: wrap;
    max-height: 470px;
    overflow: auto;
}

.imagesBox::-webkit-scrollbar {
    display: none;
}

.imagesBoxCard {
    margin: 10px;
    margin-top: 1px;
    display: inline-block;
    background-color: rgba(16, 23, 35, 0.5);
    border-radius: 7px;
    border: 1px solid #4b5663;
    /*border-radius: 8px;*/
}

.imagesBoxImage {
    width: 275px;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 0;
}

.imageText {
    margin-bottom: 0;
    color: white;
    font-size: 11px;
    margin-top: 7px;
}

.imageText a {
    color: #efefef;
}

.imagesBoxCard button {
    color: white;
    background-color: rgba(16, 23, 35, 0.5);
    border: 1px solid #4b5663;
    border-radius: 7px;
    /*border: none;*/
    width: 35px;
    height: 32px;
    cursor: pointer;
}

.imageInfo {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    padding-top: 3px;
    justify-content: space-between;
}

.mainFinderNavCategories {
    margin-top: 205px;
    margin-right: 20px;
    /*padding-top: 1px;*/
    /*padding-bottom: 1px;*/
    /*padding-right: 10px;*/
    /*padding-left: 10px;*/
    border-radius: 8px;
    background: #1f2a37;
    border: 1px solid #4b5663;
    padding: 7px;
}

.category {
    background-color: rgba(16, 23, 35, 0.5);
    margin-bottom: 0;
    border-radius: 7px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    border: 1px solid #4b5663;
    height: 30px;
}

.category + * {
    margin-top: 10px;
}

.category:hover {
    outline: none;
    border-color: #2ea44f;
}

.categoryText {
    font-size: 20px;
    /*height: 32px;*/
    margin-top: -1px;
    color: #cdcdcd;
}

.mainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    /*.mainFinder {*/
    /*    width: 100%;*/
    /*    height: auto;*/
    /*    flex-direction: column;*/
    /*    margin-top: 50px;*/
    /*}*/
    /*.mainFinderNav {*/
    /*    width: 100%;*/
    /*    padding: 15px;*/
    /*    border-bottom-left-radius: 0;*/
    /*    border-top-right-radius: 12px;*/
    /*}*/
    /*.mainFinderContent {*/
    /*    width: 100%;*/
    /*    padding: 15px;*/
    /*    height: 700px;*/
    /*}*/
    /*.mainFinderInput {*/
    /*    width: 75%;*/
    /*}*/
    /*.imagesBoxImage {*/
    /*    width: 100%;*/
    /*    height: 200px;*/
    /*}*/
    /*.imagesBoxCard {*/
    /*    width: 86%;*/
    /*}*/
    /*.imagesBox {*/
    /*    min-height: 650px;*/
    /*    margin-bottom: 0;*/
    /*}*/
    /*.mainFinderNavCategories {*/
    /*    margin-top: 20px;*/
    /*    width: 86%;*/
    /*}*/



    .mainFinder {
        width: 83%;
        min-height: 575px;
        flex-direction: column;
        margin-top: -10px;
        /*margin-left: 15px;*/
        margin-left: -2px;
        margin-right: 0;
    }
    .mainFinderNav {
        width: 90.8%;
        padding: 15px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 12px;
    }
    .mainFinderContent {
        width: 100%;
        padding: 15px;
        height: 100px;
    }
    .mainFinderInput {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 80%;
        height: 40px;
    }
    form {
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
    .mainFinderButton {
        height: 49.96px;
    }
    .mainFinderButtonIcon {
        margin-left: -3px;
    }
    .imagesBoxImage {
        width: 100%;
        height: 180px;
    }
    .imagesBoxCard {
        width: 82%;
    }
    .imagesBox {
        margin-top: -10px;
        min-height: 470px;
        margin-bottom: 0;
        margin-left: 5px;
    }
    .mainFinderNavCategories {
        display: none;
        /*margin-top: 20px;*/
        /*width: 93.8%;*/
    }
    .noImagesText {
        display: none;
    }
    .mainContent {
        width: 100%;
        /*margin-left: 15px;*/
        /*margin-right: auto;*/
        /*background: red;*/
    }
    .downloadButton {
        margin-left: -1px;
    }

    /*.mainFinderButton:hover {*/
    /*    margin-left: -1px;*/
    /*}*/
}

/* Medium devices (landscape tablets, 768px and up) */
/*@media only screen and (min-width: 768px) {*/
/*    .mainFinder {*/
/*        width: 100%;*/
/*        height: auto;*/
/*        flex-direction: row;*/
/*        margin-top: 50px;*/
/*    }*/
/*    .mainFinderNav {*/
/*        width: 25%;*/
/*        padding: 15px;*/
/*    }*/
/*    .mainFinderContent {*/
/*        width: 75%;*/
/*        padding: 15px;*/
/*    }*/
/*    .mainFinderInput {*/
/*        width: 80%;*/
/*    }*/
/*    .imagesBoxImage {*/
/*        width: 50%;*/
/*        height: auto;*/
/*    }*/
/*}*/

/*!* Large devices (laptops/desktops, 992px and up) *!*/
/*@media only screen and (min-width: 992px) {*/
/*    .mainFinder {*/
/*        width: 80%;*/
/*        height: auto;*/
/*        flex-direction: row;*/
/*        margin-top: 110px;*/
/*    }*/
/*    .mainFinderNav {*/
/*        width: 20%;*/
/*        padding: 20px;*/
/*    }*/
/*    .mainFinderContent {*/
/*        width: 80%;*/
/*        padding: 20px;*/
/*    }*/
/*    .mainFinderInput {*/
/*        width: 60%;*/
/*    }*/
/*    .imagesBoxImage {*/
/*        width: 33%;*/
/*        height: auto;*/
/*    }*/
/*}*/