.servicesTitle {
    /*font-size: 2.5rem;*/
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    color: white;
    font-size: 40px;
}

.servicesBox {
    width: 1450px;
    /*background: blue;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*margin-top: 50px;*/
    margin-right: 40px;
    /*margin-right: 50px;*/
}

.serviceImage {
    width: 471.2px;
    border-radius: 7px;
    /*margin-right: 50px;*/
}

.servicesTexts {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    /*margin-left: 50px;*/
}

.servicesTextsTitle {
    font-size: 35px;
    font-weight: bold;
    margin-top: 0;
    color: #2fff2f;

}

.servicesTextsDescription {
    color: white;
    font-size: 18px;
}

.service-imageFinder {
    width: 100%;
    /*background-color: orange;*/
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    margin-top: 20px;
    padding: 20px;
}

.servicesTextsBenefits {
    color: white;
    font-size: 20px;
    text-align: left;
    /*margin-top: 20px;*/
    /*margin-left: auto;*/
    background-color: rgba(17, 25, 40, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 10px;
    /*margin-left: 50px;*/
}

.servicesTextsBenefitsTitle {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.servicesTextsBenefitsListItem {
    font-size: 18px;
}

.services-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    margin-left: 40px;
}

.line {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin: 0 10px;
}

.services-more-text {
    font-size: 15px;
    /*color: #666;*/
    color: white;
    font-weight: bold;
}

.services-more-text2 {
    font-size: 14px;
    /*color: #666;*/
    color: white;
}