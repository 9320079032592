.welcomeTitle {
    color: white;
    font-size: 40px;
}

.welcomeSubtitle2 {
    color: white;
    font-size: 25px;
}

.welcomeSubTitle {
    color: white;
    font-size: 20px;
}

.welcomeSubtitleSpan2 {
    display: block;
}

.welcomeLoginButton {
    margin-top: 30px;
    width: 20%;
    padding: 15px;
    border: none;
    border-radius: 4px;
    background-color: #2ea44f;
    border-color: #2ea44f;
    box-shadow: 0 1px 1px rgba(18, 21, 26, .075);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.welcomeLoginButton:hover {
    background-color: #2b9749;
}


@media only screen and (max-width: 768px) {
    .welcomeTitleSpan {
        display: block;
    }
}