header {
    position: fixed;
    top: 0;
    background-image: linear-gradient(to right, #6f1b3d, #000b24);
    backdrop-filter: blur(30px);
    width: 96.14%;
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 22px;
    display: flex;
    align-items: center;
}

.serviceTitle {
    color: #588bf8;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    margin: 0; /* Removed margin to ensure better centering */
}

.mainHeaderContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: center;
}

#userIcon {
    color: #ffffff;
    width: 27px;
    margin-bottom: 3px;
    /*margin-top: 18px;*/
}

.titleContainer {
    flex: 1;
    display: flex;
    /*justify-content: center;*/
}

.header2NavigationSpan {
    color: white;
    /*margin-left: 20px;*/
    /*margin-right: 20px;*/
    cursor: pointer;
    text-decoration: none;
}

.header2NavigationSpanItem {
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

.loginImageText {
    color: white;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    /*margin-top: -5px;*/
}

.headerNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerNavigationContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    text-decoration: none;
    list-style-type: none;
    font-size: 20px;
}

.headerNavigationItem {
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
}

.titleContainer2 {
    /*margin-left: auto;*/
    align-items: center;
    /*margin-bottom: 15px;*/
    /*display: flex;*/
}

.titleContainer2 button {
    display: flex;
    align-items: center;
    height: 30px;
    width: 120px;
    padding: 15px;
    border: none;
    border-radius: 4px;
    background-color: #2ea44f;
    border-color: #2ea44f;
    box-shadow: 0 1px 1px rgba(18, 21, 26, .075);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.titleContainer2 button:hover {
    background-color: #2b9749;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    header {
        height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
    }
    .serviceTitle {
        margin-left: 8px;
        margin-top: 13px;
        text-align: center;

    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    header {
        height: 55px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
    }
    .serviceTitle {
        margin-top: 12px;
        /*text-align: center;*/
    }
}

/*!* Medium devices (landscape tablets, 768px and up) *!*/
/*@media only screen and (min-width: 768px) {*/
/*    header {*/
/*        height: 60px;*/
/*        padding-left: 25px;*/
/*        padding-right: 25px;*/
/*        font-size: 22px;*/
/*    }*/
/*    .serviceTitle {*/
/*        margin-top: 13px;*/
/*    }*/
/*}*/